import React, { useState, useEffect }  from "react";
import { Helmet } from "react-helmet";
import Gallery from "react-photo-gallery"; // Masonry gallery
import ImageGallery from 'react-image-gallery'; // Sliding gallery
import { useForm } from 'react-hook-form';
import Slider from "react-slick";
import axios from "axios";

import { BiPhoneCall, BiEnvelope } from "react-icons/bi";
import { FaWpforms } from "react-icons/fa";

import "../style/index.scss";

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 650,
  slidesToShow: 1,
  autoplay: true,
  arrows: true,
};


const wallImages = [
  {
    original: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/large_wall5_b5da1cd075.png',
    thumbnail: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/thumbnail_wall5_b5da1cd075.png',
  },
  {
    original: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/large_anchors_095f047abf.JPG',
    thumbnail: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/thumbnail_anchors_095f047abf.JPG'
  },
  {
    original: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/large_wall4_f584db13da.png',
    thumbnail: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/thumbnail_wall4_f584db13da.png',
  },
  {
    original: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/large_grinder_b41b02e10b.JPEG',
    thumbnail: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/thumbnail_grinder_b41b02e10b.JPEG'
  },
  {
    original: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/large_course1_cc45809df2.JPG',
    thumbnail: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/thumbnail_course1_cc45809df2.JPG',
  },
  {
    original: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/large_course2_44e09b90aa.JPG',
    thumbnail: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/thumbnail_course2_44e09b90aa.JPG',
  },
  {
    original: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/large_autobelays_ac45047f9c.JPG',
    thumbnail: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/thumbnail_autobelays_ac45047f9c.JPG'
  },
];

const raImages = [
  {
    original: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/large_person1_4d3c0526f3.JPG',
    thumbnail: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/thumbnail_person1_4d3c0526f3.JPG'
  },
  {
    original: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/large_access1_185e1f1254.JPG',
    thumbnail: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/thumbnail_access1_185e1f1254.JPG',
  },
  {
    original: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/large_lift_097e05e08c.JPG',
    thumbnail: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/thumbnail_lift_097e05e08c.JPG'
  },
  {
    original: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/large_access2_2e30de34cd.JPEG',
    thumbnail: 'https://pinnacle-public-assets.s3.nl-ams.scw.cloud/thumbnail_access2_2e30de34cd.JPEG',
  }
];

const personImages = [
  {
    src: "https://pinnacle-public-assets.s3.nl-ams.scw.cloud/large_person3_426b603419.png",
    width: 2,
    height: 3
  },
  {
    src: "https://pinnacle-public-assets.s3.nl-ams.scw.cloud/large_person4_f9b2298b46.JPEG",
    width: 2,
    height: 3
  }
];

const Index = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [submitted, setSubmitted] = useState(false);

    const onSubmit = async (data) => {
      setSubmitted(true);
      const response = await axios.post(process.env.GATSBY_MAIL_SUBMISSION_URI, data)
      .then(response => {
            return;
        }).catch(error => {
            return;
      });
    }

    return(
        <>
            <Helmet>
                <title>Seima Safety</title>
                <link rel="canonical" href="https://seimasafety.com.au/" />
                <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" /> 
                <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
                <link rel="apple-touch-icon" sizes="180x180" href={"/apple-touch-icon.png"} />
                <link rel="icon" type="image/png" sizes="32x32" href={"/favicon-32x32.png"} />
                <link rel="icon" type="image/png" sizes="16x16" href={"/favicon-16x16.png"} />
                <link rel="manifest" href={"/site.webmanifest"} />
            </Helmet>
            <div id="highlight-strip"></div>
            <div className="header-image-container"></div>
            <div className="brand-container">
              <img className="brand-logo" src={"/logo.png"} alt="Seima Safety logo." />
              <span className="brand-motto">Climbing Walls • Rope Access • Inspections</span>
            </div>
            <div className="content-container move-up">
                <div className="contact-card">
                    <h1 className="call-to-action">Contact us, get your solution.</h1>
                    <div className="contact-details">
                        <div><a href="tel:0433977065"><BiPhoneCall className="contact-icon" /> <span>0433 977 065</span></a></div>
                        <div><a href="mailto:info@seimasafety.com.au"><BiEnvelope className="contact-icon" /> <span>info@seimasafety.com.au</span></a></div>
                        <div><a href="#quote"><FaWpforms className="contact-icon" /> <span className="underline">Online form</span></a></div>
                    </div>
                </div>
                <main>
                    <p>Seima Safety is an industry leader in providing simple solutions to complex technical problems at height. Based in Brisbane, Australia, we are equipped with decades of professional rope access experience, as well as an eye to detail that ensures our solutions follow Australian safety standards and are tailored to our clients’ specific needs.</p>
                    <Gallery photos={personImages} direction={"row"} />

                    <h1>Services We Provide</h1>

                    <h2>Challenge Courses and Climbing Walls</h2>
                    <p>Seima Safety has its routes deep in the world of outdoor recreation and rock climbing. As a member of the Highpoint Adventures group and a partner of Pinnacle Sports, we understand the requirements sought for constructing ropes courses and artificial climbing walls to provide the most enjoyable and safe experience for the user possible. Utilising quality materials and equipment, we construct solutions that scale to the size and requirements of your facility. To organise an inspection of your facility and a quote, contact us through the form at the base of this page.</p>
                    <ImageGallery
                      items={wallImages}
                      showPlayButton={false}
                      lazyLoad={true}
                    />

                    <h2>Equipment Logging, Testing, and Certification</h2>
                    <p>Seima Safety provides equipment inspection and maintenance in accordance with Australian Standards. We will also manage a complete database of your equipment and send alerts when compliance testing is due for renewal. We offer this services to both industrial and recreational users of safety equipment used at height.</p>
                    
                    <h2>Industrial Rope Access</h2>
                    <p>Seima Safety specialise in working at height where access via conventional scaffolding or elevated work platform is cost prohibitive or infeasible. We provide economical and expedient solutions for all your site or building maintenance needs. Contact us with the form at the bottom of this page to enquire about how our services can be enlisted to suit you.</p>
                    <p>Our team is highly qualified and experienced with qualifications in ARAA, IRATA and Rigging.</p>
                    <ImageGallery
                      items={raImages}
                      showPlayButton={false}
                      lazyLoad={true}
                    />

                    <h2>Anchor Installations and Tagging</h2>
                    <p>Seima Safety’s structural engineering team assesses each site in detail before ensuring that fit for purpose anchor systems are installed safely and quickly. Our services extend from highrise rooftops to aerial acrobatics gyms and everything in between.</p>
                    <p>We also provide a range of cost-effective proof-testing and tagging services for a range of anchor systems. All testing is carried out to AS/NZ and International Standards and all testers are calibrated in a NATA certified Lab.</p>

                    <h2>Equipment Retail and Training</h2>
                    <p>Our partner Pinnacle Sports is a recreational and industrial equipment retailer with a collective lifetime of experience between our team to ensure you are equipped with the gear that best suits your use case, experience level, and safety requirements. They are a veritable source of wisdom that comes from passion and first-hand experience.</p>
                    <p>Pinnacle Sports also offers nationally recognised courses to get you out on the cliffs in a safely knowing you have been taught the most relevant and up to date information.</p>

                    {/*<h1>Testimonials</h1>
                    <Slider {...sliderSettings}>
                      <div>
                        <div className="slide-content-container">
                          <span className="slide-quote">Seima installed all of the things with a great manner of precession and care!</span>
                          <span className="slide-attribution">Albert</span>
                        </div>
                      </div>
                      <div>
                        <div className="slide-content-container">
                          <span className="slide-quote">I wouldn't go anywhere else.</span>
                          <span className="slide-attribution">Rodney</span>
                        </div>
                      </div>
                      <div>
                        <div className="slide-content-container">
                          <span className="slide-quote">When I thought all was lost, Seima swooped in and saved the day.</span>
                          <span className="slide-attribution">Juliet</span>
                        </div>
                      </div>
                    </Slider>*/}

                    <h1 id="quote">Get A Quote</h1>
                    <p>Fill in the fields below and click ‘Submit’ to send us an email with your enquiry. We’ll get back to you over email as soon as possible. You will receive a confirmation email to the address you enter.</p>
                    <div>
                        <form className="form" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-section">
                                <span className="form-label required">Email</span>
                                <input className="form-input-text" type="text" placeholder="Email" {...register("Email", {required: true, pattern: /^\S+@\S+$/i})} />
                                {errors.Email?.type === 'required' && <span className="form-input-error">Email is a required field</span>}
                                {errors.Email?.type === 'pattern' && <span className="form-input-error">This email address is invalid</span>}
                            </div>
                            <div className="form-section">
                                <span className="form-label required">Full Name</span>
                                <input className="form-input-text" type="text" placeholder="Name" {...register("Name", {required: true})} />
                                {errors.Name?.type === 'required' && <span className="form-input-error">Full Name is a required field</span>}
                            </div>
                            <div className="form-section">
                                <span className="form-label required">Subject</span>
                                <input className="form-input-text" type="text" placeholder="Subject" {...register("Subject", {required: true})} />
                                {errors.Subject?.type === 'required' && <span className="form-input-error">Subject is a required field</span>}
                            </div>
                            <div className="form-section">
                                <span className="form-label required">Message</span>
                                <textarea className="form-input-textarea" placeholder="Message" {...register("Message", {required: true})} />
                                {errors.Message?.type === 'required' && <span className="form-input-error">Message is a required field</span>}
                            </div>

                            <div className="form-section">
                                <button className="form-button draw meet" type="submit">Submit</button>
                                {submitted && <div className="form-submit-success">Thank you for sending your enquiry. Please check your inbox to make sure you've received an email confirmation of your message. We will respond at your email address as soon as possible.</div>}
                            </div>
                        </form>
                    </div>
                </main>
            </div>

            <div className="footer-section">
                <div className="footer-content-container">
                    <div className="footer-content">
                        <ul>
                            <li>info@seimasafety.com.au</li>
                            <li>0433 977 065</li>
                            <li>Unit 1, 237 Montague Road, West End, Brisbane, Queensland 4101</li>
                            <li>© Seima Safety 2021</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;